import React, { useEffect, useState } from 'react';
import {
  ToastContainer,
  Header,
  Footer,
  ErrorContainer,
  BlockLoader,
  NotificationManager,
  IEBanner,
  HEADER_HEIGHT,
  Loader,
  Maintenance,
} from '@sky-tv-group/components';
import { myAccountSWR } from './swr';
import { getUser, useAuth0 } from './auth0/store';
import { routes } from './routes';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  pageErrorMessage,
  productStoreApi,
  T_Product,
  useCustomerStore,
  useOrderStore,
  useProductStore,
  orderStoreApi,
  useBroadband,
  getTuiAddressCode,
  couponStoreApi,
  useManagePackage,
  useSplitIO,
  SPLITIO_KEY,
  useAnalytics,
  CreditCampaignCodes,
  productSkuIds,
  // SPAs,
  // PACKAGE_TYPE,
} from '@sky-tv-group/shared';

import { kkService, prequalService } from './services';
import { DOMAIN, LIFERAY_CONNECT_ENDPOINT, ACCOUNT_URL } from './config';
import { TotalHeader } from './components/total-header';
import { SubMenu } from './components/SubMenu';
import { T_Profile } from '@sky-tv-group/shared/src/types';
import { set, clearSessionStorageState, useStore } from './store';
import { useLocation } from 'react-router-dom';
import { AddressBar } from './components/AddressBar';
import { AppRoutes } from './constants';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  profile: T_Profile | undefined;
  packages: T_Product[] | undefined;
  isAuthenticated: boolean;
}

let Body = ({ profile, packages, isAuthenticated }: Props) => {
  const [BBCreditOfferFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_CREDIT_OFFER);

  const [errorPresent, setErrorPresent] = useState(false);
  const [productsUpdated, setProductsUpdated] = useState(false);
  const { data: billing }: any = myAccountSWR.useBilling();
  const { customerId, getTempCustomerId, getCustomerId, setExistingCustomerId } = useCustomerStore(s =>
    // Selecting parts of store to bind to root component
    ({
      customerId: s.kk,
      getTempCustomerId: s.getTempCustomerId,
      getCustomerId: s.getCustomerIdFromProfile,
      setExistingCustomerId: s.setExistingCustomerId,
    })
  );
  const location = useLocation();
  const showAddressBarInHeader = location.pathname === AppRoutes.Home;

  const {
    loadProductStore,
    initializedProductStore,
    updateFromOrderProducts,
    updateBillingProductsFromOrderProducts,
    setProductStoreUpgrade,
  } = useProductStore();

  const { loadOrderStore, updateOrder, order } = useOrderStore();

  const updateFromOrderProductsCall = (authenticated: boolean) => {
    const orderProducts = orderStoreApi.getState().order?.orderProducts;
    // order with a coupon will have things that wont be in query2 (this is for no existing campaign).
    // Need to toggle those products
    if (orderProducts && !authenticated) {
      updateFromOrderProducts(orderProducts);
    } else if (packages && authenticated) {
      updateBillingProductsFromOrderProducts(packages, orderProducts ?? []);
    }
  };

  const { isCustomerNeedsAccountUpdate } = myAccountSWR.usePackages();

  useEffect(() => {
    const updateOrderAtStart = async (customerId: string) => {
      try {
        if (isAuthenticated && profile && (!customerId || customerId === '')) {
          // If current user is authenticated but customerId is not in storage (maybe redirected from another domain),
          // wait for his profile to load so we can properly load his existing order
          customerId = getCustomerId(profile);
          setExistingCustomerId(customerId);
        } else if (!isAuthenticated && (!customerId || customerId === '')) {
          // Customer is guest and new
          customerId = await getTempCustomerId(kkService);
        } else if (isAuthenticated && profile && (customerId || customerId !== '')) {
          // Customer is logged in, has profile and customerId in storage.
          const profileCustomerId = getCustomerId(profile);
          // If customerId in storage differs from profileCustomerId
          // Do steps:
          //  1. Retrieve the customerId's order from KK so we get his existing product orders
          //  2. Update order store and product store quantity from the response of step 1
          //  3. Set profileCustomerId as the new customerId
          //  4. Update KK order using his profileCustomerId
          if (profileCustomerId !== customerId) {
            await loadProductStore(kkService, customerId, false, billing);

            updateFromOrderProductsCall(isAuthenticated);

            customerId = profileCustomerId;
            setExistingCustomerId(profileCustomerId);

            // If logged in Customer is Vodafone or Igloo customer do not add products
            if (!isCustomerNeedsAccountUpdate) {
              await updateOrder(
                kkService,
                profileCustomerId,
                productStoreApi.getState().getBasketItemsToAddToOrder(),
                couponStoreApi.getState().coupons
              );
            }
          }
        }

        if (customerId && customerId !== '') {
          await loadOrderStore(kkService, customerId);
          await loadProductStore(kkService, customerId, false, billing);

          updateFromOrderProductsCall(isAuthenticated);

          // If logged in Customer is Vodafone or Igloo customer do not add products
          if (!isCustomerNeedsAccountUpdate) {
            await updateOrder(
              kkService,
              customerId,
              productStoreApi.getState().getBasketItemsToAddToOrder(),
              couponStoreApi.getState().coupons
            );
          }
          setProductsUpdated(true);
        }
      } catch (errors) {
        setErrorPresent(true);
      }
    };
    updateOrderAtStart(customerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  let fibrePro = order?.orderProducts.find(p => p.product.sku === productSkuIds.broadbandLightningFastWiFi.primary);
  let fibreProBoost = order?.orderProducts.find(
    p => p.product.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary
  );

  let fibreEveryday = order?.orderProducts.find(p => p.product.sku === productSkuIds.broadbandWifi100.primary);
  let fibreEverydayBoost = order?.orderProducts.find(
    p => p.product.sku === productSkuIds.broadbandWifi100Boost.primary
  );

  let fibreStarter = order?.orderProducts.find(p => p.product.sku === productSkuIds.broadbandEssnFibre.primary);
  let fibreStarterBoost = order?.orderProducts.find(p => p.product.sku === productSkuIds.broadbandEssnFibBoost.primary);

  useEffect(() => {
    const loadCoupons = async () => {
      if (!BBCreditOfferFlag) return;

      let coupons = Object.values(CreditCampaignCodes.BROADBAND);
      coupons.forEach(coupon => {
        couponStoreApi.getState().clearCouponFromCode(coupon);
      });

      if (fibrePro?.quantity! > 0 || fibreProBoost?.quantity! > 0) {
        const coupon = await kkService.getCoupon(CreditCampaignCodes.BROADBAND.CREDIT_BB_ONLY);
        if (window.location.pathname != AppRoutes.Home && window.location.pathname != AppRoutes.Broadband) {
          if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
        }
      }
      if (fibreEveryday?.quantity! > 0 || fibreEverydayBoost?.quantity! > 0) {
        const coupon = await kkService.getCoupon(CreditCampaignCodes.BROADBAND.CREDIT_BB_ONLY);
        if (window.location.pathname != AppRoutes.Home && window.location.pathname != AppRoutes.Broadband) {
          if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
        }
      }
      if (fibreStarter?.quantity! > 0 || fibreStarterBoost?.quantity! > 0) {
        const coupon = await kkService.getCoupon(CreditCampaignCodes.BROADBAND.CREDIT_BB_ONLY);
        if (window.location.pathname != AppRoutes.Home && window.location.pathname != AppRoutes.Broadband) {
          if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
        }
      }
      if (
        (fibreEveryday || fibrePro || fibreEverydayBoost || fibreProBoost || fibreStarter || fibreStarterBoost) &&
        window.location.pathname === AppRoutes.OrderSummary
      ) {
        await updateOrder(
          kkService,
          customerId,
          productStoreApi.getState().getBasketItemsToAddToOrder(),
          couponStoreApi.getState().coupons
        );
      }
      updateFromOrderProducts(orderStoreApi.getState().order?.orderProducts ?? []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    loadCoupons();
  }, [
    fibrePro?.quantity,
    fibreProBoost?.quantity,
    fibreEveryday?.quantity,
    fibreEverydayBoost?.quantity,
    window.location.pathname === AppRoutes.OrderSummary,
  ]);

  useEffect(() => {
    // Tell analytics whether it's upgrade or acquisition.
    setProductStoreUpgrade(isAuthenticated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFromOrderProductsCall(isAuthenticated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, isAuthenticated]);

  const navLinks = useAuth0(s => s.navLinks);

  // only true if we catch an error on load - for konakart
  if (errorPresent)
    return (
      <>
        <ErrorContainer
          headerText={pageErrorMessage.header}
          lineOne={pageErrorMessage.lineOne}
          lineTwo={pageErrorMessage.lineTwo}
        />
      </>
    );

  if (!(initializedProductStore && productsUpdated) && isAuthenticated)
    return (
      <div className="h-128">
        <BlockLoader />
      </div>
    );

  return (
    <div className="flex flex-col">
      <div className="sticky z-40 w-full left-0 overflow-x-auto shadow-md" style={{ top: HEADER_HEIGHT }}>
        {showAddressBarInHeader && <AddressBar />}
        <TotalHeader />
      </div>
      <div className="flex-1">
        <div>
          <NotificationManager notificationsEndpoint={LIFERAY_CONNECT_ENDPOINT} domain={DOMAIN} />
          <Switch>
            {routes.map(({ path, exact, component }) => (
              <Route key={path} exact={exact} path={path} component={component} />
            ))}
            <Route path="*">
              <Redirect to="/notfound" />
            </Route>
          </Switch>
        </div>
        <Footer links={navLinks} />
        <ToastContainer />
      </div>
    </div>
  );
};

const App = () => {
  const [isMaintenanceMode] = useSplitIO(SPLITIO_KEY.MAINTENANCE_MODE_BROADBAND);
  const [isSurvicateEanbled] = useSplitIO(SPLITIO_KEY.ENABLE_SURVICATE);
  const { data: profile } = myAccountSWR.useProfile();
  const { data: packages, hasActiveOrPendingBroadbandPackage, summerSetAccount } = myAccountSWR.usePackages();
  const { data: customer } = myAccountSWR.useCustomer();
  const { data: billing } = myAccountSWR.useBilling();
  const { isAuthenticated, loginWithRedirect, login, logout, navLinks } = useAuth0();
  const { identify, page } = useAnalytics();

  useEffect(() => {
    if (isAuthenticated) {
      if (profile) {
        // Analytics
        const user = getUser();
        identify(user.sub, profile.email, profile.accountNumber, profile.profileId);
        setTimeout(() => {
          page();
        }, 1000);
      }
    } else {
      page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, profile]);

  useEffect(() => {
    if (summerSetAccount && isAuthenticated) {
      window.location.assign(ACCOUNT_URL);
    }
  }, [summerSetAccount, isAuthenticated]);

  useEffect(() => {
    console.log({ isSurvicateEanbled });
    const eleId = 'survicate';
    if (isSurvicateEanbled) {
      (function(w) {
        var s = document.createElement('script');
        s.id = eleId;
        s.src = 'https://survey.survicate.com/workspaces/e78f97f8d7583351bf5dd2cd9cd4393f/web_surveys.js';
        s.async = true;
        var e = document.getElementsByTagName('script')[0];
        e.parentNode!.insertBefore(s, e);
      })(window);
    } else {
      document.getElementById(eleId)?.remove();
    }
  }, [isSurvicateEanbled]);

  const { getPrequalData } = useBroadband(prequalService);
  const { persistToStorage, clearStore, clearPersistedButtonStates, isBundle } = useStore(s => ({
    persistToStorage: s.persistToStorage,
    clearPersistedButtonStates: s.clearPersistedButtonStates,
    clearStore: s.clearStore,
    isBundle: s.isBundle,
  }));
  const { setExistingCustomerId, customerId, sessionId, setSessionId } = useCustomerStore(s => ({
    setExistingCustomerId: s.setExistingCustomerId,
    customerId: s.kk,
    sessionId: s.sessionId,
    setSessionId: s.setSessionId,
  }));

  const { orderProductsAddedToCart } = useManagePackage(myAccountSWR);

  const loginCallbackUrls: string[] = [AppRoutes.Home, AppRoutes.Broadband].map(r => `${window.location.origin}${r}`);

  const queryParams = useLocation().search;

  const loginHandler = () => {
    const allowLogin = loginCallbackUrls.includes(window.location.href);
    // only / and /broadband route is allowed for login callback redirects, everything else route to home.
    // this is because we don't allow existing customers from logging-in in acquisition urls
    // like '/checkout' and '/tv', it messes up their order
    //
    // also if a bundle plan is selected, we redirect and clear cart
    if (!allowLogin || isBundle) {
      // clear cart using orderStoreApi to also clear the order store (kkService.updateOrder doesn't)
      orderStoreApi.getState().clearOrder(kkService, customerId);
      login();
    } else {
      loginWithRedirect(ACCOUNT_URL);
    }
  };

  const logoutHandler = async () => {
    clearPersistedButtonStates();
    clearSessionStorageState();
    await kkService.updateOrder(customerId, []);
    // clear id on logout so guests will use a different id
    setExistingCustomerId('');
    logout();
  };

  async function setCustomerPrequal() {
    if (customer) {
      //Clear guest prequal and selected address when you login
      clearStore();

      let tui = getTuiAddressCode(customer);
      let prequal = await getPrequalData(tui);
      // NOTE: I'm using the 'set' method from store to avoid re-rendering this Home component when store state changes
      //Set selectedAddressId to empty on login
      set({ authTuiAddress: tui, selectedAddressId: '', customer: customer });
      // if prequal is present, else show toast maybe?
      if (prequal) {
        set({ authenticatedPrequalData: prequal, useGuestPrequal: false });
      }
      set({ isLoadingPrequal: false });
      persistToStorage();
    }
  }

  useEffect(() => {
    // If there's no session Id and there are new products in the cart and user is logged in, it means that this was abandoned.
    if (!sessionId && isAuthenticated && orderProductsAddedToCart.length > 0) {
      // Commenting out this event for now.  Will revisit later.
      // returnToAbandonedCart(
      //   orderProductsAddedToCart,
      //   true,
      //   SPAs.BROADBAND,
      //   isBundle ? PACKAGE_TYPE.DTH_BROADBAND : PACKAGE_TYPE.BROADBAND
      // );
      //console.log("%cUSER JUST LOGGED IN!!!!!", "background-color: yellow")

      // After you've triggered an abandoned cart event, generate a new session for this journey.
      if (!sessionId) {
        setSessionId(uuidv4());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderProductsAddedToCart.length > 0, isAuthenticated, sessionId]);

  // Updating prequal if user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      set({ isLoadingPrequal: true });
      setCustomerPrequal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    set({ hasActiveOrPendingBroadbandPackage });
  }, [hasActiveOrPendingBroadbandPackage]);

  useEffect(() => {
    // Get the session ID from the URL if it exists.
    const sessionIdParam = queryParams ? new URLSearchParams(queryParams).get('sid') : '';
    if (sessionIdParam) {
      setSessionId(sessionIdParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if customer is authenticated,
  // wait for his profile data and billing to be fully loaded before rendering the body
  if (isAuthenticated && (!profile || !billing || !customer)) return <Loader bgOpacity={false} />;

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={window.location.protocol + '//' + window.location.host} />
      </Helmet>
      <Header
        isSignedIn={isAuthenticated}
        name={profile?.displayName || profile?.firstName}
        SessionClickHandler={isAuthenticated ? logoutHandler : loginHandler}
        links={navLinks}
      />
      <SubMenu />
      {isMaintenanceMode ? (
        <Maintenance />
      ) : (
        <>
          <IEBanner>
            <Body profile={profile} packages={packages} isAuthenticated={isAuthenticated} />
          </IEBanner>
        </>
      )}
    </div>
  );
};

export { App };
