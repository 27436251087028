import React, { useEffect, useState } from 'react';
import { GenericPromotionalCard, GenericPromotionaCardDetails } from '../GenericPromotionalCard';
import { Button } from '../button';
import { useModal } from '../ExploreModal/useModal';
import {
  IPopUpContentConfig,
  JSONConfigService,
  KonakartService,
  redirectToSubdomain,
  T_PlanSelector,
  T_Product,
  useJSONConfig,
} from '@sky-tv-group/shared';
import ExplorePage, { ExplorePageHeader } from '../ExplorePage';
import AnimatedModal from '../AnimatedModal';
import ExploreModal from '../ExploreModal/ExploreModal';
import { BBExploreModal } from '../ExploreModal/BBExploreModal';

export interface GenericProductCardProps {
  card: GenericPromotionaCardDetails;
  bgColor?: string;
  kkProduct?: T_Product;
  kkService?: KonakartService;
  disabled?: boolean;
  subscribed?: boolean;
  fixedWidth?: number;
  isStarterInCart?: boolean;
  skyPriceChangePopUp?: IPopUpContentConfig[] | undefined;
  handleProductCardCTA?: (inCart: boolean, product?: T_Product) => Promise<void>;
  secondaryProductCardCTA?: () => void;
  compactMode?: boolean;
  inCart?: boolean;
  configService?: JSONConfigService;
  plan?: T_PlanSelector;
  selectedTuiAddress?: string;
}

export function GenericProductCard({
  card,
  kkProduct,
  bgColor,
  compactMode,
  handleProductCardCTA,
  secondaryProductCardCTA,
  kkService,
  disabled,
  subscribed,
  skyPriceChangePopUp,
  fixedWidth,
  inCart,
  isStarterInCart,
  configService,
  plan,
  selectedTuiAddress,
}: GenericProductCardProps) {
  const { showModal, toggleModal } = useModal();

  const [showChannelsPage, setShowChannelsPage] = useState(false);
  const config = configService ? useJSONConfig(configService) : null;
  const _inCart = inCart ?? (!!kkProduct && kkProduct.quantityInCart > 0);
  const labelInCart = subscribed ? 'Subscribed' : _inCart ? 'Remove' : card.ctaName ? card.ctaName : 'Add';
  const productDetails = {
    bgColor: bgColor ?? card.bgColor ?? '#1F0124',
    billingFrequency: card.billingFrequency,
    campaign: card.campaign,
    disclaimer: card.disclaimer,
    discountText: card.discountText,
    discountedPrice: card.discountedPrice,
    fromPrice: card.fromPrice,
    imageSrc: card.imageSrc,
    lozengeText: card.lozengeText,
    sku: card.sku,
    occurrenceType: card.occurrenceType,
    subtitle: card.subtitle,
    termsAndConditions: card.termsAndConditions,
    title: card.title,
    type: card.type,
    coupon: card.coupon,
    descriptionList: card.descriptionList,
    iconList: card.iconList,
    copyrightText: card.copyrightText,
    ctaName: card.ctaName,
  };

  const [isDisabled, setIsDisabled] = useState(disabled);
  const [isLoading, setIsLoading] = useState(false);

  const onProductToggle = async (product?: T_Product) => {
    setIsDisabled(true);
    setIsLoading(true);
    handleProductCardCTA && handleProductCardCTA(_inCart, product);
    setIsDisabled(false);
    setIsLoading(false);
  };

useEffect(() => {
setIsDisabled(disabled)
},[disabled])

  return (
    <>
      <GenericPromotionalCard
        cardType="PRODUCT"
        compactMode={compactMode}
        card={productDetails}
        buttons={
          <div className={`flex flex-row relative ${compactMode ? '' : 'mt-sky-lg'} w-full p-5 justify-center`}>
            {kkService && (kkProduct || plan?.product) && (
              <Button
                variant="primary"
                colorMode="light"
                onClick={() => {
                  toggleModal();
                }}>
                Explore
              </Button>
            )}

            <Button
              onClick={
                handleProductCardCTA && kkService
                  ? () => onProductToggle(kkProduct)
                  : () => {
                      if (secondaryProductCardCTA) secondaryProductCardCTA();

                      if (card.addLink) {
                        window.open(card.addLink, '_blank')?.focus();
                      } else {
                        redirectToSubdomain('ICE', `?skus=${card.sku}`, true);
                      }
                    }
              }
              className="flex-1"
              style={{ maxWidth: 250 }}
              disabled={isDisabled}
              colorMode={_inCart ? 'pink' : isDisabled ? 'disabled' : 'pure'}
              icon={isLoading ? 'loading' : _inCart ? 'check' : undefined}
              variant="primary"
              data-testid={'add-product-' + kkProduct?.sku}>
              {labelInCart}
            </Button>
          </div>
        }
        bgColor={card.bgColor ?? bgColor}
        fixedWidth={fixedWidth}
      />
      {plan && kkService && (
        <>
          {card.occurrenceType === 'Data' && (
            <>
              {configService && config && plan && (
                <BBExploreModal
                  isShown={showModal}
                  toggleProduct={async (_: any) => {
                    if (plan.product) onProductToggle(plan.product);
                  }}
                  kkService={kkService}
                  hide={toggleModal}
                  plan={plan}
                  product={plan.product!}
                  isStarterInCart={isStarterInCart ?? false}
                  content={config?.broadband?.acquisition?.speedShowCase}
                  configService={configService!}
                  selectedTuiAddress={selectedTuiAddress}
                  parentPrice={productDetails.discountedPrice}
                />
              )}
            </>
          )}
        </>
      )}
      {card.occurrenceType !== 'Data' && kkProduct && handleProductCardCTA && kkService && (
        <>
          <ExploreModal
            isShown={showModal}
            kkService={kkService}
            toggleProduct={onProductToggle}
            hide={toggleModal}
            product={kkProduct}
            subscribed={subscribed}
            skyPriceChangePopUp={skyPriceChangePopUp}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
}
